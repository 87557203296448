.footer {
    background-color: #c3eed2;
    min-height: 30vh;

    img {
        margin-top: 5vh;
    }
}
.footer-links {
    h3{
        color: gray;
    }
    margin-top: 5vh;
    ul{
        padding: 0;
        color: gray;
    }

    li{
       list-style: none;
   }
   a{
       color: gray;
   }
  
}

.dev-text{
    margin-top: 8vh;
    font-size: 11px;
    color: white;
    
}